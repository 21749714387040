// Required
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

// Optional
@import "~bootstrap/scss/spinners";
@import "~bootstrap/scss/toasts";
@import "~bootstrap/scss/transitions";
@import "~bootstrap/scss/alert";
@import "~bootstrap/scss/pagination";
@import "~bootstrap/scss/helpers/visually-hidden";

// Custom 

// ----------------- TOAST COMPONENT ----------------- //

#toasts {
    position: fixed;
    top: 45px;
    right: 30px;
    z-index: 999;
}

.close {
    float: right;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    opacity: .5;
    outline: 0;
}

// .fade {
//     transition: opacity 5s linear;
// }

button.close {
    padding: 0;
    background-color: transparent;
    border: 0;
    margin-left: auto;
    outline: 0;
}

//.visually-hidden {
//    position: absolute;
//    width: 1px;
//    height: 1px;
//    padding: 0;
//    margin: -1px;
//    overflow: hidden;
//    clip: rect(0, 0, 0, 0);
//    white-space: nowrap;
//    border: 0;
//    outline: 0;
//}

.mr-auto, .mx-auto {
    margin-right: 20px !important;
}

.toast-body {
    background-color: white;
    color: black;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

// ----------------- ALERT COMPONENT ----------------- //

#alerts {
    position: fixed;
    top: 15px;
    left: 50%;
    transform: translate(-50%, 0);
    width: 80%;
    max-width: 700px;
    z-index: 999;
}

// ----------------- PAGINATION COMPONENT ----------------- //
.pagination-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 10px 0 10px;
}

.page-link {
    border: 1px solid #b3b3b338 !important;
}

.page-item .page-link {
    background-color: transparent !important;
    color: white !important;

    &:focus {
        outline: 0 !important;
        box-shadow: none !important;
    }
}

.page-item {

    &:hover:not(.active) {
        .page-link {
            background-color: #252525 !important;
        }
    }

}

.page-item.active .page-link {
    color: $--spotify-main-color !important;
}


// .page-item.disabled .page-link {
// 	color: #b3b3b371 !important;
// }
