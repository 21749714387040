@import './variables';

.loaderParent {
    background-color: $--loader-bg-color;
    position: fixed;
    padding: 0;
    margin: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: opacity $--loader-fade-out-background;
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loader {
    position: absolute;
    width: 6em;
    height: 6em;
    border: 1.1em solid $--loader-border-transp;
    border-left: 1.1em solid $--loader-border-main;
    border-radius: 50%;
    animation: load8 1.1s infinite linear;
    transition: opacity $--loader-fade-out-spinner;
    box-shadow: 0px 0px 15px rgba(255, 255, 255, 0.2);
}

.loaderDivParent {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loaderDiv {
    position: absolute;
    width: 6em;
    height: 6em;
    border: 1.1em solid $--loader-border-transp;
    border-left: 1.1em solid $--loader-border-main;
    border-radius: 50%;
    animation: load8 1.1s infinite linear;
    transition: opacity $--loader-fade-out-spinner;
    box-shadow: 0px 0px 15px rgba(255, 255, 255, 0.2);
}

button {
    .loader-div {
        width: 30px;
        height: 30px;
        border: 6px solid $--loader-border-transp;
        border-left: 6px solid $--loader-border-main;
    }
}

.loader--hide {
    opacity: 0;
}

@keyframes load8 {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}