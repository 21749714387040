@import './variables';

//:export {
::-webkit-scrollbar {
    width: $--scrollbar-width;
}

/* Track */
::-webkit-scrollbar-track {
    background: $--scrollbar-track;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: $--scrollbar-thumb;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: $--scrollbar-thumb-hover;
}

//}
