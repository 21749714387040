// ----------------- APP WIDE CSS ----------------- //

@import './variables';

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    // font-family: $--main-font-family; // implemented through next
}

body {
    color: $--main-font-color;
    background-color: $--main-bg-color;
    // font-size: $--main-font-size;
}

body, html, #__next {
    overflow: hidden !important;
    height: 100%;
}


// div {
// 	display: block;
// }

#app {
    width: 100%;
    height: 100%;
    overflow: hidden !important;
}

[type=button]:not(:disabled), [type=reset]:not(:disabled), [type=submit]:not(:disabled), button:not(:disabled) {
    cursor: pointer;
}

a {
    text-decoration: none;
}

.highlight-a {
    color: white;
    transition: color .15s ease-in-out;
    cursor: pointer;

    &:hover {
        outline: none;
        color: rgba(255, 255, 255, 0.7);
    }
}