@import './variables';

.recharts-default-tooltip {
    background-color: $--main-bg-color !important;
    border: 1px solid #535353 !important;

    p {
        font-size: 1.5em !important;
    }
}

.recharts-brush {
    rect:first-child {
        fill: transparent !important;
    }

    .recharts-brush-traveller {
        rect {
            fill: rgb(179, 179, 179) !important;
        }
    }
}

.recharts-cartesian-axis-ticks {
    font-size: 0.8em;
}
