// Breakpoints
$--breakpoint-xs: 370px;
$--breakpoint-sm-xs: 420px;
$--breakpoint-sm: 576px;
$--breakpoint-md: 768px;
$--breakpoint-lg: 992px;
$--breakpoint-xl: 1200px;

// Main
$--main-bg-color: #181818;
$--main-font-family: 'Montserrat', sans-serif;
$--main-font-color: white;
$--main-font-size: 16px;

// Loader
$--loader-bg-color: $--main-bg-color;
$--loader-border-transp: rgba(255, 255, 255, 0.1);

$--loader-border-main: white;
$--loader-fade-out-background: 500ms;
$--loader-fade-out-spinner: $--loader-fade-out-background;

// Account
$--account-transition-speed: 0.3s;

// Spotify
$--spotify-main-color: rgb(28, 178, 81);
$--spotify-main-color-faded: rgba(30, 215, 95, 0.3);

// SideNavBar
$--sidenavbar-active-link: $--spotify-main-color;

// Scroll bar
$--scrollbar-width: 14px;
$--scrollbar-track: $--main-bg-color;
$--scrollbar-thumb: #535353;
$--scrollbar-thumb-hover: #B3B3B3;